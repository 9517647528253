import { ExclamationCircleOutlined } from '@ant-design/icons';
import { faCircleCheck, faClock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { message, Modal, Tag } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  DEFAULT_ERROR_MESSAGE_DURATION,
  DEFAULT_SUCCESS_MESSAGE_DURATION,
} from '../../utils/appVars';
import CurrencyIcon from '../shared/Currency';
import { EntityActionButtons } from '../shared/EntityActionButtons';
import './styles/checkCard.scss';
import {
  accountForId,
  accountFullName,
  convertApiDateStringToDisplayString,
} from '../../utils/helpers';
import { CheckStatus } from '../../@types/enums';
import { useMutation } from 'react-query';
import { Check } from '../../@types/Check';
import { deleteCheckApi } from '../../api/checks';
import CheckFormModal from './CheckFormModal';
import { refreshChecks } from '../helpers/storeHelper';

interface CheckCardProps {
  check: Check;
  allTags: string[];
  editMode: boolean;
  filterApplied?: boolean;
}

interface CheckCardState {
  checkFormModalVisible: boolean;
}

const CheckCard = ({
  check,
  allTags,
  editMode,
  filterApplied = false,
}: CheckCardProps) => {
  const { t, i18n } = useTranslation();

  const deleteCheckMutation = useMutation(async () => deleteCheckApi(check.id));

  const [state, setState] = useState({
    checkFormModalVisible: false,
  } as CheckCardState);

  const fromAccount = accountForId(check.from_account_id)!;
  const toAccount = accountForId(check.to_account_id)!;

  const closeModals = () => {
    setState({} as any);
  };

  /*
   * Update Modal
   */

  const onSaveCheck = async (mutationInfo: any) => {
    await refreshChecks();
    closeModals();
  };

  const checkFormModal = state.checkFormModalVisible && (
    <CheckFormModal
      onSave={onSaveCheck}
      onCancel={closeModals}
      check={check}
      allTags={allTags}
    />
  );

  const openCheckFormModal = (check: Check) => {
    setState((state) => ({
      ...state,
      checkFormModalVisible: true,
    }));
  };

  /*
   * Delete Modal
   */

  const onDeleteCheck = async () => {
    try {
      const data = await deleteCheckMutation.mutateAsync();

      message.success(
        t(`generic.messages.success`),
        DEFAULT_SUCCESS_MESSAGE_DURATION,
      );

      await refreshChecks();
    } catch (error) {
      console.log('error', error);
      message.error(
        t(`generic.errors.operationFailed`),
        DEFAULT_ERROR_MESSAGE_DURATION,
      );
    }
  };

  const openDeleteCheckModal = () => {
    Modal.confirm({
      title: t('check.deleteForm.title'),
      icon: <ExclamationCircleOutlined />,
      content: t('check.deleteForm.body'),
      okText: t('generic.actions.yes'),
      okType: 'danger',
      cancelText: t('generic.actions.no'),
      onOk: onDeleteCheck,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onCancel() {},
    });
  };

  /*
   * Action buttons
   */
  const checkActionButtons = () => {
    if (!editMode) return <></>;

    return (
      <EntityActionButtons
        ownerEntity={check}
        translationIndex="check"
        onEditButton={openCheckFormModal}
        onDeleteButton={openDeleteCheckModal}
      />
    );
  };

  /*
   * UI parts
   */

  const dateInfoLine = () => {
    return convertApiDateStringToDisplayString(check.date);
  };

  const checkStatus =
    check.status === CheckStatus.PENDING ? (
      <span style={{ color: 'gray' }}>
        <FontAwesomeIcon icon={faClock} color="gray" />{' '}
        {t('check.checkStatuses.pending')}
      </span>
    ) : (
      <span style={{ color: 'green' }}>
        <FontAwesomeIcon icon={faCircleCheck} color="green" />{' '}
        {t('check.checkStatuses.applied')}
      </span>
    );

  return (
    <>
      <div className="check-card__container">
        <div className={`check-card__line check-card__entity-control-buttons`}>
          {checkActionButtons()}
        </div>
        <div className="check-card__line">
          <div className="check-card__description">{check.description}</div>
        </div>
        <div className="check-card__line" style={{ width: 'max-content' }}>
          <div className="check-card__action-type">{checkStatus}</div>
          <div className="check-card__amount">
            <span>
              <CurrencyIcon currencyType={fromAccount.currency} />
            </span>
            <span className="check-card__amount__value">
              {Number(check.amount || 0).toLocaleString()}
            </span>
          </div>
        </div>
        <div className="check-card__line">
          <div className="check-card__date-info">{dateInfoLine()}</div>
        </div>
        <div className="check-card__line">
          <span>{`${t('check.words.fromAccount')}: ${accountFullName(
            fromAccount,
          )}`}</span>
        </div>
        <div className="check-card__line">
          <span>{`${t('check.words.toAccount')}: ${accountFullName(
            toAccount,
          )}`}</span>
        </div>
        <div className="check-card__line">
          <div className="check-card__tags">
            {check.tags.map((tag, index) => (
              <Tag key={`check_tag_${index}`}>{tag}</Tag>
            ))}
          </div>
        </div>
      </div>
      {checkFormModal}
    </>
  );
};

export default CheckCard;
