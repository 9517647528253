import React, { useEffect } from 'react';
import { PrivatePageProps } from './PrivatePageWrapper';
import './styles/checksPage.scss';
import ChecksList from '../../components/check/ChecksList';
import { useTranslation } from 'react-i18next';

const ChecksPage = ({ updateHeaderTitle }: PrivatePageProps) => {
  const { t } = useTranslation();

  useEffect(() => {
    updateHeaderTitle(t('check.title'));
  }, []);

  return (
    <div className="checks-page__container">
      <ChecksList />
    </div>
  );
};

export default ChecksPage;
