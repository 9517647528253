export const downloadCSV = async (
  filename: string,
  fetchRowObjects: () => Promise<[string[]]>,
) => {
  const rowsArray = await fetchRowObjects();
  const csvRows = (rowsArray || []).map(
    (row) => (row.join(',') || '') + '\r\n',
  );
  const csvData = new Blob(csvRows, { type: 'text/csv' });
  const csvURL = URL.createObjectURL(csvData);
  const link = document.createElement('a');
  link.href = csvURL;
  link.download = `${filename}.csv`;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
