import { Input, InputNumber, message, Modal, Select } from 'antd';
import { isEmpty } from 'lodash';
import React, { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  DEFAULT_ERROR_MESSAGE_DURATION,
  DEFAULT_SUCCESS_MESSAGE_DURATION,
} from '../../utils/appVars';
import FormModalFooter from '../shared/FormModalFooter';
import './styles/checkFormModal.scss';
import { useMutation } from 'react-query';
import DatePicker from '../shared/DatePicker';
import {
  accountForId,
  accountFullName,
  convertApiDateStringToDate,
  convertDateToApiDateString,
  extractAllAccountsFromCachedAccountGroups,
} from '../../utils/helpers';
import { CheckParams, createCheckApi, updateCheckApi } from '../../api/checks';
import { Account } from '../../@types/Account';
import { Check } from '../../@types/Check';

interface CheckFormModalProps {
  onSave: (mutationInfo: any) => any;
  onCancel: () => any;
  check?: Check;
  allTags: string[];
}

interface CheckFormModalState {
  checkParams: CheckParams;
  possibleToAccounts: Account[];
}

const CheckFormModal = ({
  onSave,
  onCancel,
  check,
  allTags,
}: CheckFormModalProps) => {
  const { t } = useTranslation();
  const updateMode = !isEmpty(check);
  const allAccounts = extractAllAccountsFromCachedAccountGroups();
  const buildPossibleToAccounts = (fromAccount?: Account) => {
    if (!fromAccount) {
      return allAccounts;
    }

    return allAccounts.filter(
      (account) =>
        account.id != fromAccount!.id &&
        account.currency == fromAccount!.currency,
    );
  };

  const createCheckMutation = useMutation((state: CheckFormModalState) =>
    createCheckApi(state.checkParams),
  );

  const updateCheckMutation = useMutation(async (state: CheckFormModalState) =>
    updateCheckApi(check!.id, state.checkParams),
  );

  const [state, setState] = useState<CheckFormModalState>({
    checkParams: {
      amount: updateMode ? check!.amount : (undefined as any),
      description: updateMode ? check!.description : '',
      date: updateMode ? check!.date : convertDateToApiDateString(new Date()),
      from_account_id: updateMode ? check!.from_account_id : (undefined as any),
      to_account_id: updateMode ? check!.to_account_id : (undefined as any),
      tags: check?.tags || [],
    },
    possibleToAccounts: buildPossibleToAccounts(
      updateMode ? accountForId(check!.from_account_id) : undefined,
    ),
  });

  /*
   * Helpers
   */
  const isCheckInputValid = (checkParams: CheckParams) => {
    return (
      !isEmpty(checkParams) &&
      !isEmpty(checkParams.description) &&
      checkParams.from_account_id &&
      checkParams.to_account_id &&
      checkParams.amount > 0
    );
  };

  /*
   * Actions and event handlers
   */
  const onCheckAmountChange = (amount: number | null) => {
    setState((state: any) => ({
      ...state,
      checkParams: { ...state.checkParams, amount: amount || 0 },
    }));
  };

  const onCheckAttributeChange = (e: ChangeEvent<HTMLInputElement>) => {
    const fieldName = e.target.name;
    const fieldValue = e.target.value;
    setState((state: any) => ({
      ...state,
      checkParams: {
        ...state.checkParams,
        [fieldName]: fieldValue,
      },
    }));
  };

  const onCheckSelectedAccountsChange = (
    attribute: 'from_account_id' | 'to_account_id',
    selectedAccountIdOrName: string | number,
  ) => {
    const selectedAccount = allAccounts.find(
      (account) =>
        selectedAccountIdOrName == account!.id ||
        selectedAccountIdOrName == accountFullName(account!),
    );

    let possibleToAccounts = state.possibleToAccounts;
    if (attribute == 'from_account_id') {
      possibleToAccounts = buildPossibleToAccounts(selectedAccount!);
    }

    setState({
      ...state,
      checkParams: {
        ...state.checkParams,
        [attribute]: selectedAccount!.id,
      },
      possibleToAccounts: possibleToAccounts,
    });
  };

  const onCheckDateChange = (
    selectedDate: Date,
    dateString: string | string[],
  ) => {
    const date = selectedDate || new Date();
    setState({
      ...state,
      checkParams: {
        ...state.checkParams,
        date: convertDateToApiDateString(date),
      },
    });
  };

  const onCheckTagsChange = (tags: string[]) => {
    console.log('change, tags', tags);
    setState((oldState) => ({
      ...oldState,
      checkParams: {
        ...oldState.checkParams,
        tags: [...(tags || [])],
      },
    }));
  };

  const onSaveClick = async (event: any) => {
    event.stopPropagation();

    const saveFn = updateMode ? updateCheckMutation : createCheckMutation;
    try {
      const mutationResponse = await saveFn.mutateAsync(state);

      message.success(
        t(`generic.messages.success`),
        DEFAULT_SUCCESS_MESSAGE_DURATION,
      );

      await onSave(mutationResponse);
    } catch (error) {
      console.log('error', error);
      message.error(
        t(`generic.errors.operationFailed`),
        DEFAULT_ERROR_MESSAGE_DURATION,
      );
    }
  };

  const onCancelClick = (event: any) => {
    event.stopPropagation();
    onCancel();
  };

  /*
   * UI parts
   */

  const fromAccountDropdown = (
    <Select
      placeholder={t('check.form.selectFromAccount')}
      value={
        state.checkParams.from_account_id &&
        accountFullName(accountForId(state.checkParams.from_account_id)!)
      }
      onChange={(selectedAccountIdOrName: string | number) =>
        onCheckSelectedAccountsChange(
          'from_account_id',
          selectedAccountIdOrName,
        )
      }
      className="check-form__input__from_account"
    >
      {allAccounts.map((account: Account) => (
        <Select.Option
          key={`check_from_account_selector_${account.id}`}
          value={account.id}
        >
          {accountFullName(account)}
        </Select.Option>
      ))}
    </Select>
  );

  const toAccountDropdown = (
    <Select
      placeholder={t('check.form.selectToAccount')}
      value={
        state.checkParams.to_account_id &&
        accountFullName(accountForId(state.checkParams.to_account_id)!)
      }
      onChange={(selectedAccountIdOrName: string | number) =>
        onCheckSelectedAccountsChange('to_account_id', selectedAccountIdOrName)
      }
      className="check-form__input__to_account"
      disabled={!state.checkParams.from_account_id}
    >
      {(state.possibleToAccounts || []).map((account: Account) => (
        <Select.Option
          key={`check_to_account_selector_${account.id}`}
          value={account.id}
        >
          {accountFullName(account)}
        </Select.Option>
      ))}
    </Select>
  );

  return (
    <Modal
      open
      maskClosable={false}
      title={
        updateMode ? t('check.form.title.edit') : t('check.form.title.create')
      }
      onCancel={onCancelClick}
      footer={
        <FormModalFooter
          onSaveOrDeleteClick={onSaveClick}
          onCancelClick={onCancelClick}
          loading={
            updateMode
              ? updateCheckMutation.isLoading
              : createCheckMutation.isLoading
          }
          disabled={!isCheckInputValid(state.checkParams)}
        />
      }
    >
      <div className="check-form__container" id="check_modal">
        <div className="check-form__input__container">
          <div className="check-form__input">
            <InputNumber
              className="check-form__input__amount"
              name="amount"
              value={state.checkParams.amount}
              onChange={onCheckAmountChange}
              min={0}
              step={0.1}
              placeholder={t('check.form.inputAmount')}
            />
          </div>

          <div className="check-form__input">
            <Input
              name="description"
              value={state.checkParams.description || ''}
              onChange={onCheckAttributeChange}
              size="large"
              placeholder={t('check.form.inputDescription')}
            />
          </div>

          <div className="check-form__input">{fromAccountDropdown}</div>
          <div className="check-form__input">{toAccountDropdown}</div>

          <div className="check-form__input">
            <DatePicker
              allowClear={false}
              className="check-form__input__date"
              defaultValue={convertApiDateStringToDate(state.checkParams.date)}
              format="YYYY/MM/D" // otherwise it would keep adding a zero right after the user enter the first days digit
              name={'date'}
              placeholder={t('check.form.selectDate')}
              onChange={onCheckDateChange}
              getPopupContainer={() =>
                document.getElementById('check_modal') as any
              }
            />
          </div>

          <div className="check-form__input">
            <Select
              mode="tags"
              style={{ width: '100%' }}
              placeholder={t('check.form.inputTags')}
              onChange={onCheckTagsChange}
              defaultValue={state.checkParams?.tags || []}
              options={(allTags || []).map((tag) => ({
                value: tag,
                label: tag,
              }))}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CheckFormModal;
