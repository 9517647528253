import { getRequest } from './apiHelper';
import { AccountTransactionsListResponse } from '../@types/AccountTransaction';
import { API_SERVER } from '../utils/envVars';
import { AccountTransactionType } from '../@types/enums';

export interface TransactionFilters {
  account_ids: number[];
  start_date?: string;
  end_date?: string;
  tags?: string[];
  transaction_type?: AccountTransactionType;
  from_check?: boolean;
}

export const listTransactionsApi = async (
  filters: TransactionFilters,
  {
    page,
    pageSize,
    disablePagination = false,
  }: { page?: number; pageSize?: number; disablePagination?: boolean } = {},
) => {
  const params = { ...filters } as any;
  if (page) {
    params.page = page;
  }
  if (pageSize) {
    params.items = pageSize;
  }
  if (disablePagination) {
    params.disable_pagination = disablePagination;
  }

  const apiResponse = await getRequest<AccountTransactionsListResponse>(
    `${API_SERVER}/transactions`,
    {
      ...filters,
      ...params,
    },
  );
  return apiResponse!;
};
