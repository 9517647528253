import React, { FC, useState } from 'react';
import UserSessionMenu from '../../components/shared/UserSessionMenu';
import Header from '../../components/shared/Header';
import LoadingPage from '../../components/shared/LoadingPage';
import './styles/privatePageWrapper.scss';
import { User } from '../../@types/User';
import { useQuery } from 'react-query';
import { getCurrentUserApi } from '../../api/session';
import {
  queryKeyForAccountGroupsList,
  queryKeyForAllUsers,
  queryKeyForCurrentUser,
} from '../../components/helpers/storeHelper';
import { listUsersApi } from '../../api/user';
import { isEmpty } from 'lodash';
import LanguageSelector from '../../components/shared/LanguageSelector';
import { listAccountGroupApi } from '../../api/accountGroup';

// to be used by the the entry page which is wrapped inside this page
export interface PrivatePageProps {
  updateHeaderTitle: (title: string) => any;
}

export interface PrivatePageWrapperProps {
  page: FC<any>;
}

interface PrivatePageWrapperState {
  headerTitle: string;
  forcedLoading: boolean;
}

const PrivatePageWrapper = ({
  page: PageComponent, // rename to capital to be able to use it as a tag component <Component />
}: PrivatePageWrapperProps) => {
  const { isLoading: isCurrentUserLoading, data: currentUser } = useQuery<User>(
    queryKeyForCurrentUser(),
    getCurrentUserApi,
  );

  // preload all users list
  const { isLoading: isAllUsersLoading, data: allUsers } = useQuery<User[]>(
    queryKeyForAllUsers(),
    listUsersApi,
    {
      enabled: !isCurrentUserLoading && !isEmpty(currentUser),
    },
  );

  // to load account groups... children components would read them from useQuery cache.
  const { isLoading: isAccountGroupsLoading } = useQuery(
    queryKeyForAccountGroupsList(),
    listAccountGroupApi,
  );

  const [state, setState] = useState<PrivatePageWrapperState>({
    headerTitle: '',
    forcedLoading: false,
  });

  if (
    !currentUser ||
    isCurrentUserLoading ||
    isAccountGroupsLoading ||
    isAllUsersLoading ||
    state.forcedLoading
  )
    return <LoadingPage />;

  const updateHeaderTitle = (title: string) => {
    setState((state) => ({
      ...state,
      headerTitle: title,
    }));
  };

  return (
    <>
      <div className="private-page-wrapper">
        <Header title={state.headerTitle} actions={<UserSessionMenu />} />
        {
          // @ts-ignore
          <PageComponent updateHeaderTitle={updateHeaderTitle} />
        }
      </div>
      <LanguageSelector />
    </>
  );
};

export default PrivatePageWrapper;
